export const environment = {
  envName: 'MekanicAGRO',
  production: true,
  pilot: false,
  makena: false,
  dota: false,
  agro: true,
  mkgoURL: 'https://mkgoapp.mekanicgo.com.br',
  migrate: {
    URL: 'https://invoice.mekanicgo.com.br',
    tpAmb: <1 | 2>1
  },
  DEFAULT_CLIENT_DOCUMENT: "01234567",
  DEFAULT_VEHICLE_PLATE: "MKG0000",
  firebase: {
    apiKey: "AIzaSyDjIYVIve7QMO7Xc9ek9hKYk-L1TOK9svM",
    authDomain: "mekanicgo.firebaseapp.com",
    databaseURL: "https://mekanicgo.firebaseio.com",
    projectId: "mekanicgo",
    storageBucket: "mekanicgo.appspot.com",
    messagingSenderId: "244931581171",
    appId: "1:244931581171:web:4b8e6057a7706f1b"
  },
  fraga: {
    rest: "https://accounts.fraga.com.br/realms/cat_api_tecinco/protocol/openid-connect/token",
    graphql: "https://apiv2.catalogofraga.com.br/graphql"
  },
  smartUI: "5BB0D19A-200F-4205-9AEE-D64624EF3AA2"
};
