import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { roles } from '../../app/shared/lists/roles';
import { MKG_SPEED_DIAL_STATE, MkgSpeedDialButtonChild, MkgSpeedDialConfig } from '../shared/components/speed-dial/speed-dial.component';
import { DataService } from './data.service';
import { UserService } from './user.service';
import { MEKANIC_MENU } from '../component/other/header/menu/mekanic-menu';
import { companyStates } from '../shared/lists/company-states';
import { TCAR_INTEGRATED_MENU } from '../component/other/header/menu/tcar-integrated-menu';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  menu: any = { }

  message: string;

  invoiceButtons : MkgSpeedDialButtonChild[] = [{
    label: "NFe",
    matColor: "primary",
    onClick: () => this.newInvoice('NFe')
  },
  {
    label: "NFCe",
    matColor: "primary",
    onClick: () => this.newInvoice('NFCe')
  },
  {
    label: "NFSe",
    matColor: "primary",
    onClick: () => this.newInvoice('NFSe')
  },
  {
    label: "OIP",
    matColor: "primary",
    onClick: () => this.newInvoice('OIP')
  },
  {
    label: "OIS",
    matColor: "primary",
    onClick: () => this.newInvoice('OIS')
  }];

  add: () => void;
  join: () => void;
  goBack: () => void;
  rollUp: () => void;
  newInvoice: (invoiceType) => void;
  fabs: {
    key: string;
    config: MkgSpeedDialConfig;
    initialState: MKG_SPEED_DIAL_STATE;
  }[] = [];


  invoiceSpeedDialConfig: MkgSpeedDialConfig = {
    direction: 'top',
    triggerButton: {
      icon: "add",
      matColor: "accent"
    },
    buttons: []
  }

  newUserName: string;
  countdown: number = 0;
  timeout: number = 60 * 1000; // request every 60 seconds
  static counting = false;
  /** Atribuido quando for mobile*/
  isHandset$: BehaviorSubject<boolean>;

  private _loader = false;
  private _component: string;
  private _loaderTriggers: Array<string> = []

  get loaderTriggerLength() {
    return this._loaderTriggers.length;
  }

  constructor(
    private dataService: DataService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver
  ) {

    const isHandsetBreakpoint$ = this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Small])
      .pipe(
        map(result => result.matches),
        shareReplay(1)
      );

    const isTabletBreakpoint$ = this.breakpointObserver
      .observe([Breakpoints.Tablet])
      .pipe(
        map(result => result.matches),
        shareReplay(1)
      );

    const isLandscape$ = new BehaviorSubject<boolean>(window.innerWidth > window.innerHeight);

    window.addEventListener('resize', () => {
      isLandscape$.next(window.innerWidth > window.innerHeight);
    });

    this.isHandset$ = combineLatest([isHandsetBreakpoint$, isTabletBreakpoint$, isLandscape$]).pipe(
      map(([isHandset, isTablet, isLandscape]) => {
        if (isHandset) {
          return true;
        }
        if (isTablet && !isLandscape) {
          return true;
        }
        return false;
      }),
      shareReplay(1)
    ) as BehaviorSubject<boolean>;

    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('pageSize')
    }

    if (typeof window !== 'undefined') {
      window.ononline = () => {
        this.message = undefined;
        this.loader = false
      };
      window.onoffline = () => {
        this.message = "Sem acesso à Internet";
        this.loader = true
      };
    }

    combineLatest([
      this.dataService.company$,
      this.dataService.user$
    ]).subscribe(([company, user]) => {
      if (company && user) {
        this.getConfig();
      }
    })
  }


  /** Get sidenav config from localStorage */
  private getConfig() {
    if (typeof localStorage !== 'undefined') {
      const storageConfig = localStorage.getItem('sidenav');
      let menu = { };

      if (storageConfig) {
        const config = JSON.parse(storageConfig);
        // avoid cache errors on create new menus
        menu = Object.assign(menu, config);
      } 

      const [company, user] = [this.dataService.company, this.dataService.user];

      const mekanicMenu = company.currentState === companyStates.integrated.id ? TCAR_INTEGRATED_MENU(company) : MEKANIC_MENU(company, user);

      let menusWithChildren = mekanicMenu.filter(menu => menu.childs?.length);
      menusWithChildren.push({ component: "sidenav" })

      for (let menuItem of menusWithChildren) {
        if (menu[menuItem.component]) {
          menu[menuItem.component] = true;
        } else {
          menu[menuItem.component] = false;
        }
      }

      this.menu = { ...menu, newUsers: 0};
    }
  }

  private setConfig() {
    localStorage.setItem('sidenav', JSON.stringify(this.menu));
  }

  get loader(): boolean {
    return this._loader;
  }

  /** Atention, avoid to set loader if you are inputing something, the window will lose focus */
  set loader(loader: boolean) {
    const activeElement = window.document.activeElement as HTMLElement;

    if (loader) {
      activeElement.blur();
    };

    setTimeout(() => {
      this._loader = loader;
    });
  }

  addLoaderTrigger(functionName: string) {
    if (this._loaderTriggers.includes(functionName)) {
      console.warn(`A função ${functionName} já ativou o loader`)
    } 
    
    // console.log({_loaderTriggers: this._loaderTriggers.slice(), functionName, "Boolean(this._loaderTriggers.length)": Boolean(this._loaderTriggers.length), loader: this._loader, caller: "addLoaderTrigger"})
    this._loaderTriggers.push(functionName);
    this.loader = Boolean(this._loaderTriggers.length);
    // console.log({_loaderTriggers: this._loaderTriggers.slice(), functionName, "Boolean(this._loaderTriggers.length)": Boolean(this._loaderTriggers.length), loader: this._loader, caller: "addLoaderTrigger"})
  }
  
  removeLoaderTrigger(functionName: string) {
    if (!this._loaderTriggers.includes(functionName)) {
      console.warn(`A função ${functionName} não ativou o loader`);
    }
    const functionIndex = this._loaderTriggers.findIndex(trigger => trigger === functionName);
    // console.log({_loaderTriggers: this._loaderTriggers.slice(), functionName, "Boolean(this._loaderTriggers.length)": Boolean(this._loaderTriggers.length), loader: this._loader, caller: "removeLoaderTrigger", functionIndex})
     
    if (functionIndex !== -1) {
      this._loaderTriggers.splice(functionIndex, 1);
    }
    this.loader = Boolean(this._loaderTriggers.length);
  }
  
  get component(): string {
    return this._component;
  }

  set component(component: string) {
    setTimeout(() => this._component = component);
    LayoutService.counting = true;
  }

  async lookForNewUsers() {
    LayoutService.counting = true;
    if (!this.dataService.company) {
      return
    }
    if (this.dataService.company.cnpj && this.dataService.userRole == roles.admin.id) {
      try {
        const users = await this.userService.getRequests();
        this.menu["newUsers"] = users.length
        if (!!users.length) {
          this.newUserName = users[users.length - 1].name
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  toggleSidenav(newValue?: boolean) {
    this.menu["sidenav"] = (newValue != undefined ? newValue : !this.menu["sidenav"]);

    const menuLikeArray = Object.keys(this.menu);

    if (!this.menu["sidenav"]) {
      for (let menuItem of menuLikeArray) {
        if (menuItem !== "sidenav") {
          this.menu[menuItem] = false;
        }
      }
    }
    this.setConfig();

  }

  toggle(component: string) {
    const menu = this.menu[component];
    if (menu !== undefined) {
      this.menu[component] = !menu;
      if (!menu && !this.menu.sidenav) {
        this.toggleSidenav()
      }
    }
    this.setConfig();
  }

  reset() {
    this.component = '';
    this.add = null;
    this.join = null;
    this.goBack = null;
    this.rollUp = null;
    this.newInvoice = null;
    this.menu.budgets = false;
    this.menu.ro = false;
    this.menu.parts = false;
    this.menu.calendar = false;
    this.menu.cash = false;
    this.menu.records = false;
    this.menu.invoices = false;
    this.menu.reports = false;
    this.menu.CRM = false;
    this.menu.indicators = false;
    this.menu.users = false;
    this.menu.settings = false;
  }

  scrollToElement($element: HTMLElement, horizontal: boolean): void {
    if ($element) {
      const inline = horizontal ? 'center' : 'nearest';
      // let blockOption: ScrollLogicalPosition;
      // switch ($element.id) {
      //   case this.toc[0].id:
      //     blockOption = <ScrollLogicalPosition>"center";
      //     break;
      //   case this.toc[this.toc.length - 1].id:
      //     blockOption = <ScrollLogicalPosition>"end";
      //     break;
      //   default: blockOption = <ScrollLogicalPosition>"start"
      //     break;
      // }
      $element.scrollIntoView({ behavior: "smooth", block: "center", inline: inline });
    }
  }

  public support() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      window.open('https://api.whatsapp.com/send?phone=554599800231&amp;text=Olá, estou entrando em contato pelo site', '_blank')
    } else {
      window.open('https://web.whatsapp.com/send?phone=554599800231&amp;text=Olá, estou entrando em contato pelo site', '_blank')
    }
  }

  removeFab(key: string) {
    const fabIndex = this.fabs.findIndex(fab => fab.key === key);
    if (fabIndex >= 0) {
      this.fabs.splice(fabIndex, 1);
    }
  }

}
