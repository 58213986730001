import { DecimalPipe } from "@angular/common";
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { isMoment, Moment } from "moment";
import { languages } from '../shared/lists/languages';

export class Utilities {
  static language: string = 'pt';

  /** One day, in milisseconds */
  static MS_DAY = 24 * 60 * 60 * 1000;

  static stringToNumber(value?: string | number): number {
    if (!value) {
      return 0
    }
    let stringValue = value.toString();
    const isNegative = stringValue.startsWith("-");
    const idx = stringValue.search(/[0-9\.,]/g);
    const regex = /(^(.*?)(\.|,))?[0-9]?/g;
    // console.log(`stringValue: stringValue.substring(idx)`, stringValue.substring(idx))
    // console.log(`stringValue: stringValue.substring(idx).match(regex)`, stringValue.substring(idx).match(regex))
    // console.log(`stringValue: stringValue.substring(idx).match(regex).join("")`, stringValue.substring(idx).match(regex).join(""))
    // console.log(`stringValue.substring(idx).match(regex).join("").replace(",",".")`, stringValue.substring(idx).match(regex).join("").replace(",","."))
    // console.log(`stringValue.substring(idx).match(regex).join("").replace(",",".").replace(/[0-9\.,]/g, "")`, stringValue.substring(idx).match(regex).join("").replace(",",".").replace(/[0-9\.,]/g, ""))
    stringValue = stringValue.replaceAll(/[\.-]/g, "").replace(",", ".");
    // if (stringValue.indexOf('.') === stringValue.length - 1) {
    //   [stringValue] = stringValue.split('.')
    // }
    let resp = Number(stringValue);
    return isNegative ? (resp * -1) : resp;
  }

  static getProvider(emailDomain: string): string {
    switch (emailDomain) {
      case 'hotmail':
      case 'outlook':
      case 'live':
        return 'http://outlook.live.com/default.aspx?rru=compose';
      case 'gmail':
        return 'https://mail.google.com/mail/?view=cm&fs=1';
      case 'yahoo':
        return 'https://us-mg5.mail.yahoo.com/neo/launch?action=compose';
      case 'vivaldi':
        return 'https://mail.vivaldi.net/webmail/?_task=mail&_action=compose';
      default:
        return 'mailto:'
    }
  }

  static transformTime(difference: number): string {
    if (difference <= 60) {
      return `${difference} segundo${difference === 1 ? '' : 's'} atrás`;
    } else if (difference <= 3600) {
      const minutes = Math.floor(difference / 60);
      return `${minutes} minuto${minutes === 1 ? '' : 's'} atrás`;
    } else if (difference <= 86400) {
      const hours = Math.floor(difference / 3600);
      return `${hours} hora${hours === 1 ? '' : 's'} atrás`;
    } else if (difference <= 2628000) {
      const days = Math.floor(difference / 86400);
      return `${days} dia${days === 1 ? '' : 's'} atrás`;
    } else {
      return "mais de um mês atrás";
    }
  }
  
  static sendEmail(from: string, to: string, subject: string, body: string) {
    from = ((from.split('@', 2)[1]).split('.')[0]);
    let provider = Utilities.getProvider(from);
    let strTo = (provider == 'yahoo') ? '&To=' : (provider == 'vivaldi') ? '&_to=' : '&to=';
    let strSubject = (provider == 'gmail') ? '&su=' : '&subject=';
    const href = `${provider}${strTo}${to}${strSubject}${subject}&body=${body}`
    window.open(href, '_blank')
  }

  /**
   * This will remove all non-numeric digits of a given string
   * 
   * If you want just remove the special characters, call `removeSpecialCharacters()` or `removeDiacritcs()` 
   */
  static removeMask(text: string | undefined): string {
    return String(text).replace(/\D/g, '');
  }

  static numeric(text: string): number {
    let value = Number(text.replace(/[^\d.,]/g, '').replace(/,/, '.'))
    return value
  }

  static removeDiacritics(text: string): string {
    if (!text) {
      return "";
    }
    return text.normalize('NFD').replace(/[\u0300-\u036f\&]/g, '');
  }

  // remove ".", "-" and "/" characters
  static removeCharacters(inputText: string): string {
    //const cleanedText = inputText.replace(/[.\-\\]/g, '');
    const cleanedText = inputText.replace(/[.\-\/]/g, '');
    return cleanedText;
  }

  static removeSpecialCharacters(text: string): string {
    return text.normalize("NFD").trim() // remove accents
      .replace(/[\<\>\&\'\"\“\u2018]/gm, '') // remove forbidden characters (for invoices)
      .replace(/[\p{Diacritic}]/gu, "") // remove diacritics
      .replace(/[\n\t\r]/gm, " ") // replace line breaks for simples spaces
      .replace(/[^A-Za-z0-9\:\-\/\,\.\s\!\@\$]/gm, '') // remove characters except letters, numbers, spaces, and some allowed special characters
  }

  static getDiscount(gross: number, net: number): number {
    return (gross - net) * 100 / gross;
  }

  static getNetValue(gross: number, discount: number): number {
    return this.roundNumber(gross * (1 - discount / 100));
  }

  static roundNumber(value: number): number {
    return Math.round(value * 100) / 100;
  }

  static dateAndTime(date: Date, time: string): Date {
    date.setHours(Number(time.substring(0, 2)), Number(time.substring(3)), 0, 0);
    return date;
  }

  static isImage(file: File): boolean {
    return file.type.startsWith('image/');
  }

  // static getLanguage(): string {
  //   if (!localStorage['language']) {
  //     localStorage['language'] = this.language;
  //   }
  //   return localStorage['language'] || this.language;
  // }

  static getLanguage(): string {
    try {
      const language = localStorage.getItem('language')
      if (!language) {
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('language', this.language)
        } else if (typeof sessionStorage !== 'undefined') {
          // Fallback to sessionStorage if localStorage is not supported
          sessionStorage.setItem('language', this.language)
        } else {
          // If neither localStorage nor sessionStorage is supported
          console.log('Web Storage is not supported in this environment.')
        }
      }
      return localStorage.getItem('language') || this.language
    } catch (error) {
      return this.language
    }

  }

  static getLanguageAndCountry(): string {
    for (const language of Object.keys(languages)) {
      if (languages[language].name == this.language) {
        return `${languages[language].name}-${languages[language].country}`;
      }
    }
    return null
  }

  /**
   * Validate a duplicated unique value
   * @param list The array with existent objects
   * @param field The property name of unique field
   * @returns null or a object containing the duplicated property;
   */
  public static unique<T>(currentValue: T[keyof T], list: T[], field?: keyof T): ValidatorFn {
    return (control: AbstractControl) => {
      for (let item of list) {
        let controlValue = control.value;

        if (typeof controlValue === "string") {
          controlValue = controlValue.trim();
        }

        if (!field && controlValue === item && currentValue !== controlValue) {
          return { duplicated: true };
        }
        if (item[field] === controlValue && controlValue !== currentValue) {
          return { duplicated: true };
        }
      }
      return null;
    };
  }

  /**
 * Validate if the control value match an value of another(s) passed field
 *
 * @param properties the properties to compare with the control
 * @returns null or the first repeated property as error
 */
  public static differentValues(properties: string[]): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!!control.parent && !!control.parent.value) {
        for (const property of properties) {
          if (control.value && control.value === control.parent.controls[property].value) {
            return { equal: property }
          }
        }
      }
      return null
    };
  }

  private static validateDigit(value: string, maxWeigth = 9, dvLength = 2, minWeigth = 2): boolean {
    const PRIME_NUMBER_DV = 11;
    const numberArr = value.replace(/\D/g, '').split('').map(Number);
    let digitsArr = numberArr.splice(numberArr.length - dvLength, dvLength);

    while (digitsArr.length) {
      const digit = digitsArr.splice(0, 1)[0];
      let sum = 0;
      for (let i = 0; i < numberArr.length; i++) {
        let weight;
        if (maxWeigth) {
          weight = ((numberArr.length - i - 1) % (maxWeigth - 1)) + minWeigth;
        }
        sum += numberArr[i] * weight;
      }
      let validDigit = PRIME_NUMBER_DV - (sum % PRIME_NUMBER_DV);
      validDigit = (validDigit > 9 ? 0 : validDigit);

      if (validDigit !== digit) {
        // invalid
        return false
      }
      numberArr.push(digit);
    }

    // valid
    return true;
  }


  /**
   * @param validateEquals Validate if all digits are equals
   * @param validateDigit Validate with verifying digit
   */
  static validateCPF(validateEquals = true, validateDigit = true, validateLength?: boolean) {
    const CPF_LENGTH = 11;
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value.replace(/\D/g, '');

      // Verify string length.
      if (validateLength && value.length !== CPF_LENGTH) {
        return { length: true };
      }

      if (validateEquals && (/^([0-9])\1*$/.test(value))) {
        return { equalDigits: true };
      }

      if (validateDigit && !Utilities.validateDigit(value, 12)) {
        return { digit: true }
      }

      return null;
    }
  }

  /**
 * @param validateEquals Default true - Validate if all digits are equals
 * @param validateDigit Default true - Validate with verifying digit
 */
  static validateCNPJ(validateEquals = true, validateDigit = true, validateLength?: boolean) {
    const CNPJ_LENGTH = 14;
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value.replace(/\D/g, '');

      // Verify string length.
      if (validateLength && value.length !== CNPJ_LENGTH) {
        return { length: true };
      }

      if (validateEquals && (/^([0-9])\1*$/.test(value))) {
        return { equalDigits: true };
      }

      if (validateDigit && !Utilities.validateDigit(value)) {
        return { digit: true }
      }

      return null;
    }
  }

  static distinctControls(controlName1: string, controlName2: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value1 = control.get(controlName1)?.value;
      const value2 = control.get(controlName2)?.value;

      if (value1 && value2 && value1 === value2) {
        return { controlsEquals: true };
      }

      return null;
    };
  }


  static toBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise(resolve => canvas.toBlob(blob => resolve(blob), 'image/jpeg'));
  }

  static moveCaretToEnd(element: HTMLTableCellElement) {
    const range = new Range();
    range.selectNodeContents(element);
    range.collapse(false);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  static getNumber(text: string, digits = Number.POSITIVE_INFINITY): number {
    return Number(this.removeMask(text.toString()).substring(0, digits)) / 100;
  }

  /**
 * Calculate a percentage of a number from other number
 * 
 * @param percentage the percentage to calculate
 * @param total the number equivalent to 100%
 * @param mode round or trunc the decimal points.
 *  
 * Use 'round' when work with discounts (total - x%)
 * 
 * Use 'trunc' when work with the percentage value (x% of total)
 * 
 * @example 
 * // 7% of 107.5 is 7.525
 * 
 * // to calculate 7% of 107.50
 * const v = calcPercentage(7, 107.5); // return 7.53
 * 
 * // to reduce 7% from 107.50
 * const v = calcPercentage(7, 107.5, "round"); // 99.98
 * const discount = 107.5 - resp; // return 7.52
 * 
 * // to get real value
 * const v = calcPercentage(7, 107.5, "trunc", 3); // return 7.525
 * 
 */
  static calcPercentage(percentage: number, total: number, mode?: 'trunc' | 'round', decimalPoints = 2): number {
    if(!percentage || !total){
      // prevent NaN
      return 0
    }

    switch (mode) {
      case 'round':
        const exp = Math.pow(10, decimalPoints);
        const discount = Math.round((exp * (100 - percentage) * (total / 100))) / exp;
        return Number((total - discount).toFixed(decimalPoints)) || 0;
      default:
        return Number((total * percentage / 100).toFixed(decimalPoints)) || 0;
    }
  }

  /**
   * Calculate the corresponding percentage from two values 
   * @param value the fraction value
   * @param total this value represent 100%
   * @example
   * ```
   * // calculate how percents is 30 into 120
   * getPercentage(30, 120); // return 25.00
   * ```
   */
  static getPercentage(value: number, total: number, decimalPoints = 2) {
    if (!total) {
      return 0;
    }
    return Number((value * 100 / total).toFixed(decimalPoints));
  }

  /**
   * Insert a mask into object property
   * 
   * @param value The number or string representing value
   * @param decimalConfig The min and max decimal points, default is { min: 2, max: 2 }
   * 
   * @example
   * 
   * ```
   *  const value = 30;
   *  const masked = Utilities.getMaskedNumberOf(value);
   *  console.log(masked); // '30,00'
   * ```
   */
  static getMaskedNumberOf(value: any, decimalConfig?: { min: number, max: number }): string {
    if (['', undefined, null].includes(value)) {
      return '';
    }
    const newValue = typeof value === 'string' ? Number(value) : value;
    const decimalString = decimalConfig ? `1.${decimalConfig.min}-${decimalConfig.max}` : '1.2-2';
    const maskedValue: any = new DecimalPipe('pt-BR').transform(newValue, decimalString);
    return maskedValue;
  }

  protected static equalNumberWithDefault(a: number, b: number, defaultValue: number): boolean {
    return (a == null && b == defaultValue) || a == b;
  }

  protected static equalString(a: string | undefined, b: string | undefined): boolean {
    return (!a && !b) || a == b;
  }

  public static equalUnmasked(a: string | undefined, b: string | undefined): boolean {
    return this.removeMask(a) == this.removeMask(b);
  }

  protected static equalDate(a: string, b: string): boolean {
    if (!a) {
      a = null;
    }
    if (!b) {
      b = null;
    }
    return new Date(a).getTime() == new Date(b).getTime();
  }

  /** Return a new object without NaN, null or undefined values */
  static removeNull(object: Object, recursive = false): any {
    let resp = { ...object };
    for (const key of Object.keys(resp)) {
      if (recursive && !Array.isArray(resp[key]) && typeof resp[key] === "object") {
        resp[key] = Utilities.removeNull(resp[key])
      }
      if (Number.isNaN(resp[key]) || [null, undefined].includes(resp[key])) {
        delete resp[key];
      }

      // remove empty objects
      if (resp[key] && Object.keys(resp[key]).length === 0 && Object.getPrototypeOf(resp[key]) === Object.prototype) {
        delete resp[key];
      }
    }
    return resp;
  }

  /** Sort data of array acording most recent dates into `updatedAt` field */
  static sortByUpdatedAt(arr: any[]) {
    arr.sort((a, b) => {
      if (a.updatedAt && b.updatedAt) {
        return new Date(b.updatedAt).getTime() < new Date(a.updatedAt).getTime() ? -1 : 1;
      }
      return 1;
    })
  }

  static logFormErrors(form: FormGroup) {
    let map = new Map<string, any>()
    for (const key of Object.keys(form.controls)) {
      if (form.controls[key].status === "INVALID") {
        map.set(key, form.controls[key].errors)
      }
    }
    console.log("controls inválidos", map)
  }

  static currencyFormat(value: number): string {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL'
    });
  }

  static getRangeString(date1: string | Date | Moment, date2: string | Date | Moment) {
    const startDate = isMoment(date1) ? date1.toDate() : new Date(date1);
    const endDate = isMoment(date2) ? date2.toDate() : new Date(date2);
    let rangeString = "";
    if (startDate.valueOf() && endDate.valueOf()) {
      rangeString = `(entre ${startDate.toLocaleDateString()} e ${endDate.toLocaleDateString()})`;
    } else if (Boolean(startDate.valueOf()) !== Boolean(endDate.valueOf())) {
      if (startDate.valueOf()) {
        rangeString = `(após ${startDate.toLocaleDateString()})`
      } else {
        rangeString = `(até ${endDate.toLocaleDateString()})`
      }
    }
    return rangeString;
  }

  /**
   * Sum the numeric value of each object in a provided array 
   * and change some value if the sum is different of expected value
   * 
   * @param array the array of objects.
   * @param property the name of property to be calculated - need to be numeric.
   * @param expectedValue the sum expected.
   * @optional 
   * @param distributive if false(default) adjust only the first item of array, otherwise
   * interate the array and sum or subtract 1 unit of precision until the sum be equal the expected value.
   * @param decimalPoints The precision to fix, 2 by default (for currency).
   * @returns void, but modify the original array.
   */
  static fixCentsDifference<T extends object>(array: Array<T>, accessorConfig: string | {
    property: string,
    accessor?: (item: T) => object,
  }, expectedValue: number, distributive = false, decimalPoints = 2): void {
    let property: string, accessor: (item: T) => object;

    if (typeof accessorConfig === "string") {
      property = accessorConfig;
    } else {
      property = accessorConfig.property;
      accessor = accessorConfig.accessor;
    }

    if (!Array.isArray(array)) {
      console.warn(`Invalid array`);
      return
    }

    if (!array.length) {
      console.warn(`Empty array`, array);
      return
    }

    if (!property) {
      console.warn(`Invalid property`);
      return
    }

    if (!Number.isFinite(expectedValue)) {
      console.warn("Invalid value for parameter 'expectedValue':", expectedValue)
      return
    }

    let invalidItem = !accessor ?
      array.find((item) => !Object.hasOwn(item, property)) :
      array.find((item) => !Object.hasOwn(accessor(item), property));

    if (invalidItem) {
      console.warn(`Item without property "${property as string}"`, invalidItem);
      return
    }

    invalidItem = !accessor ?
      array.find(item => typeof item[property] !== "number") :
      array.find(item => typeof accessor(item)[property] !== "number");

    if (invalidItem) {
      console.warn(`Item with invalid property "${property as string}"`, invalidItem);
      return
    }

    const precision = Math.pow(10, (decimalPoints * -1));
    const sum = !accessor ?
      Number((array.reduce((acc, item) => acc + (item[property] as number), 0).toFixed(decimalPoints))) :
      Number((array.reduce((acc, item) => acc + (accessor(item)[property] as number), 0).toFixed(decimalPoints)));
    let difference = Number((expectedValue - sum).toFixed(decimalPoints));

    if (distributive) {
      let correctionIndex = 0; // start fixing by first item
      while (Math.abs(difference) >= precision) {
        if (difference > 0) {
          if (!accessor) {
            array[correctionIndex][property] = Number(((array[correctionIndex][property] as number) + precision).toFixed(decimalPoints)) as any;
          } else {
            accessor(array[correctionIndex])[property] = Number(((accessor(array[correctionIndex])[property] as number) + precision).toFixed(decimalPoints)) as any;
          }

          difference -= precision;
        } else {
          if (!accessor) {
            array[correctionIndex][property] = Number(((array[correctionIndex][property] as number) - precision).toFixed(decimalPoints)) as any;
          } else {
            accessor(array[correctionIndex])[property] = Number(((accessor(array[correctionIndex])[property] as number) - precision).toFixed(decimalPoints)) as any;
          }

          difference += precision;
        }
        correctionIndex = (correctionIndex + 1) % array.length;
      }
    } else if (Math.abs(difference) >= precision) {
      if (!accessor) {
        array[0][property] = Number(((array[0][property] as number) + difference).toFixed(decimalPoints)) as any;
      } else {
        accessor(array[0])[property] = Number(((array[0][property] as number) + difference).toFixed(decimalPoints)) as any;
      }
    }
  }

  static removeMongoProperties(apiObject: any, deleteID = true): object {
    const copy = { ...apiObject };
    delete copy.updatedAt;
    delete copy.createdAt;
    delete copy.company;
    delete copy.seq;
    delete copy.__v;
    if(deleteID){
      delete copy._id;
    }
    return copy;
  }

}
