import { NFSE_PATTERN } from "../shared/lists/nfse-patterns";
import { COMPANY_TAB } from "../shared/lists/company-tabs";
import { Address } from "./address";
import { BOOLEAN_TWO } from "../shared/common-enums/boolean-two";
import { BOOLEAN_ONE } from "../shared/common-enums/boolean-one";

export enum COMPANY_STATE {
  test,
  active,
  blocked,
  integrated,
  MEKANICGO_WEB,
  MAKENA_ADMIN,
  MAKENA_CLIENT,
  PO360,
}

/**
 * @external
 * Keep WabAdmin updated with these same values
 */
export enum FRAGA_CATALOG {
  "BLOCKED" = 1,
  "PAID" = 2,
  "FREE" = 3
}

export enum SEND_CODE {
  "ALLOW" = 1,
  "BLOCK" = 2
}

export enum TYPE_VEHICLE {
  "CAR" = 1,
  "TRUCK" = 2,
  "AGRO" = 3,
  "MOTORCYCLE" = 4,
  "BUS" = 5
}

export interface Company {
  id?: string;
  socialName?: string;
  fancyName?: string;
  cnpj?: string; // Cadastro Nacional da Pessoa Jurídica

  /**
   * Código de Regime Tributário
   *
   * "1" - Simples Nacional
   *
   * "2" - Simples Nacional – excesso de sublimite de receita bruta
   *
   * "3" - Regime Normal
   *
   * "4" - Simples Nacional - MEI
   */
  crt?: '1' | '2' | '3' | '4';
  currentState?: COMPANY_STATE;
  owner?: string;
  address?: Address;
  createdAt?: string;
  stateRegistration?: string;
  phone?: string;
  phone2?: string;
  email?: string;
  /** Checked by default, consider null or undefined as true */
  sendCode?: SEND_CODE;
  companyLogo?: string;
  /** Checked by default, consider null or undefined as true */
  complementaryOS?: BOOLEAN_TWO;
  discountControl?: BOOLEAN_TWO;
  associateLogo?: string;
  favorite?: number;
  osCount?: BOOLEAN_TWO;
  sms?: number;
  fcmTopic?: string;
  fcmTopics?: [];
  lang?: number;
  typeVehicle?: TYPE_VEHICLE;
  costAlert?: BOOLEAN_TWO;
  infoCarQuery?: number;
  // absent in the API
  infoCarContracted?: number;
  companyLogoBlob?: Blob;
  companyLogoInput?: string;
  associationLogoBlob?: Blob;
  associationLogoInput?: string;
  site?: string;
  tokenWhats?: string;
  /**
   * if true, try integrate with migrate after create company
   **/
  migrateIntegrated?: boolean;

  /** If true, forbid negative stock **/
  controlStock?: boolean;


  /**
   * Código de tributação do município (Codígo LC)
   */
  tributMunicipio?: string;

  numberInvoice?: string;
  numberInvoiceNFC?: string;
  numberInvoiceNFS?: string;
  numberGerencial?: string;

  serieInvoice?: number;
  serieInvoiceNFC?: number;
  serieInvoiceNFS?: number;
  serieGerencial?: number;

  authorized?: Array<string>;

  /** used for migrate integration */
  accessKeyInvoice?: string;

  /** used for migrate integration */
  lastNSU?: string;

  /** Natureza da operação
   *
   * an code of a item from `NATUREZAS_DA_OPERACAO`
   **/
  natOp?: string;

  /** Operação de tributação
   *
   * A – Sem dedução
   * B – Com dedução / materiais
   * C - Imune / Isenta de ISSQN
   * D - Devolução
   * J - Intermediação
   **/
  descDSFNET?: 'A' | 'B' | 'C' | 'D' | 'J';

  /** Regime Especial de Tributação do Prestador
   *
   * - 1 – Microempresa municipal
   * - 2 – Estimativa
   * - 3 – Sociedade de profissionais
   * - 4 – Cooperativa
   * - 5 – Microempresário Individual (MEI)
   * - 6 – Microempresário e Empresa de Pequeno Porte (ME EPP)
   * - 7 – Optante pelo Simples Nacional (Exclusivo Elotech e GLC Consultoria 2.0)
   * - 8 - Tributação Normal  (Exclusivo E&L)
   * - 9 - Autônomo (Exclusivo E&L)
   * - 10 - Variável (Exclusivo GLC Consultoria 2.0)
   * - 11 - Lucro Real (Exclusivo Digifred)
   * - 12 - Lucro Presumido (Exclusivo Digifred)
   * - 13 - Sociedade de Profissionais Pessoa Jurídica (Exclusivo SEMFAZ)
   * - 14 - Não (Exclusivo NF-Eletrônica)
   * - 15 - Notas Totalizadoras  (Exclusivo NF-Eletrônica)
   * - 16 - Inscrito no PRODEVAL  (Exclusivo NF-Eletrônica)
   */
  regEspTrib?: string;

  /** Código de Verificação de RPS
   *
   * Alguns padrões precisam liberar uma faixa de RPS no site da prefeitura antes de emitir.
   * Além do número do RPS, é necessário informar o código de verificação que é fornecido nesta lista.
   */
  verificaRPS?: string;

  /**
   * Padrão de nota fiscal utilizado pela prefeitura município da empresa para emissão de NFSe
   */
  patternNFSE?: NFSE_PATTERN;

  /** Inscrição Municipal */
  IM?: string;

  /**
   * Exigibilidade do ISS em notas de Serviços (NFSe)
   * natOp e tpTributacao em notas de Peças (NFe/NFCe)
   *
   * usar codigos da const NATUREZAS_DE_OPERAÇÃO
   */
  exigibilidade?: number;

  /** Município de incidência de ISS */
  munIncidISS?: "Não informa" | "Cliente" | "Prestador";

  /** Alíquota de ISS */
  ISS?: number;

  /**
   *  Valor mínimo para retenção de ISS
   *
   * @TODO não está na tela, mas está no banco
   */
  minISS?: number;


  /** Codigo CNAE - Classificação Nacional de Atividades Econômicas */
  cnaeCode?: string;

  /** The cashier value in money since last cashier opening, use CashierService to retrieve updated data */
  cashierValue?: number;

  /** @ignore Used into Makeasy system */
  brand?: string;
  /** @ignore Used into Makeasy system */
  tab?: COMPANY_TAB[];

  /** Checked by default, consider null or undefined as true */
  openOS?: BOOLEAN_ONE;

  /**
   * @optional
   * An masked CNPJ, used to work with multiple filials
   *
   * For some requests, the CNPJ in header is different, in order to get/save
   * the data on the matrix company instead the current logged-in company
   */
  cnpjMatrix?: string;

  /**
   * if true, the company prohibits the creation of a low with a date earlier than the title creation date
   */
  controlFinc?: boolean;

  /** Indicate if the company can use the fraga service to consult catalogs */
  fragaCatalog?: FRAGA_CATALOG;

  /**
   * Indicate if federal retention values should be reduced of NFSe values generated from OSs
   *
   * Attention: ISS is not a federal retention (is municipal), use `deduISS` to configure it
   */
  deduNFSE: boolean;

  /** Indicate if federal retention values should be reduced of NFe/NFCe values generated from OSs */
  deduNFE: boolean;

  /** Indicate if ISS retention should be deduced of NFSe values */
  deduISS: boolean;

  /** Indicate if the company can use the boleto service */
  boletos?: number;
}
